import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { EntranceMethodDto } from '@fleet-customer/shared/entrance-method'
import { FleetAccountEntranceMethodViewDto } from '../../site-details-page/entrance-methods-tab/store/dto'

export interface EntranceMethodResponse {
  entranceMethods: FleetAccountEntranceMethodViewDto[]
}

@Injectable({ providedIn: 'root' })
export class DeliveryProcessOverviewApi {
  constructor(private http: HttpClient) {}

  findEntranceMethods(fleetAccountId: string) {
    return this.http.get<EntranceMethodResponse>(
      `/DeliveryProcessOverview/${fleetAccountId}/entranceMethods`
    )
  }

  createEntranceMethod(
    fleetAccountId: string,
    entranceMethod: EntranceMethodDto
  ) {
    return this.http.post<EntranceMethodResponse>(
      `/DeliveryProcessOverview/${fleetAccountId}/entranceMethods`,
      { entranceMethod }
    )
  }

  updateEntranceMethod(
    fleetAccountId: string,
    entranceMethod: EntranceMethodDto
  ) {
    return this.http.put<EntranceMethodResponse>(
      `/DeliveryProcessOverview/${fleetAccountId}/entranceMethods/${entranceMethod._id}`,
      { entranceMethod }
    )
  }

  deleteEntranceMethod(fleetAccountId: string, entranceMethodId: string) {
    return this.http.delete<EntranceMethodResponse>(
      `/DeliveryProcessOverview/${fleetAccountId}/entranceMethods/${entranceMethodId}`
    )
  }
}
